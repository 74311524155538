.ui-images {
  .ui-image-dropzone {
    height: 129px;
    background: url('../../assets/images/asset--no-image.svg') no-repeat #ececef center left;
    border-radius: 8px;

    .dropzone-wrap {
      height: 100%;
      overflow-x: auto;
      cursor: pointer;

      .thumbnails {
        padding: 0 5px;
        white-space: nowrap;

        .thumbnail {
          position: relative;
          display: inline-block;
          border-radius: 10px;
          margin: 10px 5px 0;
          background-size: cover;
          background-color: #f8f8f8;
          background-position: center center;

          .file-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .file-remove {
            position: absolute;
            top: 8px;
            right: 8px;
            border: none;
            border-radius: 20px;
            background-color: #AAAAAA;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 19px;
            width: 19px;
            height: 19px;
            padding: 0;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .ui-button {
      margin-bottom: 8px;
    }
  }
}