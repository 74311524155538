.wysiwyg,
.wysiwyg .ql-editor {
    font-size: 14px;
    line-height: 1.6;

    p {
        margin: 10px 0;

        &.ql-align-left {
            text-align: left;
        }

        &.ql-align-center {
            text-align: center;
        }

        &.ql-align-right {
            text-align: right;
        }

        &.ql-align-justify {
            text-align: justify;
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    img {
        max-width: 100%;
    }

    &.inner .ql-editor {
        padding: 0 15px;

        & > *:first-child {
            margin-top: 0;
        }
    }
}

.wysiwyg {
    .ql-snow {
        &.ql-container {
            border: none;

            .ql-editor {
                width: 100%;
                padding: 0 12px;
                border-radius: 12px;
                resize: vertical;
                background: #FFFFFF;
                color: #222222;
                margin: 0 0 18px;
            }
        }

        &.ql-toolbar {
            border: none;
            padding: 0 0 6px 0;

            .ql-formats {
                border-right: 1px solid #555555;
                margin-right: 5px;
                padding-right: 5px;

                .ql-fill {
                    fill: #AAAAAA;
                }

                .ql-stroke {
                    stroke: #AAAAAA;
                }
            }
        }
    }
}