.ui-image {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .ui-icon {
    cursor: pointer;
  }

  .thumbnail {
    width: 89px;
    height: 89px;
    border-radius: 8px;
    background-size: cover;
    background-position: center center;
  }

  .filename {
    position: absolute;
    top: 5px;
    left: 5px;
    max-width: calc(100% - 20px);
    padding: 5px;
    line-height: 1.3;
    font-size: 13px;
    word-break: break-all;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
  }
}
