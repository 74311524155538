@import url('//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');

i[class^='icon--'] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: default;

  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &.icon--emblem {
    width: 32px;
    height: 32px;
    background-image: url('../images/icon--emblem.svg');
  }

  &.icon--loader {
    background-image: url(../images/asset--loader.svg);
    width: 34px;
    height: 34px;
  }
}