.template-search-list {
  .list-data {
    height: calc(100vh - 72px - 48px);

    .list-container {
      overflow: auto;
      position: relative;

      table {
        position: relative;
        min-width: 100%;
        min-height: 100%;

        thead {
          tr {
            th {
              position: sticky;
              z-index: 2;
              top: 0;
              padding: 16px 12px 10px;
              text-align: left;
              white-space: nowrap;
              background: #555555;
              color: #AAAAAA;
              font-size: 12px;
              font-weight: bold;

              &:first-child {
                position: sticky;
                z-index: 3;
                left: 0;

                @media (max-width: 600px) {
                  position: static;
                }
              }

              &.th-detail {
                position: sticky;
                z-index: 3;
                right: 0;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              height: 14px;
              padding: 10px 12px;
              text-align: left;
              white-space: nowrap;
              max-width: 300px;
              text-overflow: ellipsis;
              overflow: hidden;
              background: #333333;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              transition: background-color 0.15s ease,
                          border-bottom-color 0.15s ease,
                          color 0.15s ease;

              &:first-child {
                position: sticky;
                z-index: 1;
                left: 0;
                background: #444444;

                @media (max-width: 600px) {
                  position: static;
                }
              }

              &.td-detail {
                position: sticky;
                z-index: 1;
                right: 0;
                width: 24px;
                padding: 0;
                background: #333333;

                button {
                  transform: translateY(2px);
                  background: transparent;
                  border: none;
                  color: #3154FF;
                  font-size: 16px;
                  cursor: pointer;
                  transition: color 0.15s ease,
                              opacity 0.15s ease;

                  &:hover {
                    color: #FFFFFF !important;
                  }
                }
              }
            }

            &:last-child {
              td {
                border-bottom: none;
                height: auto;
                padding: 0;
              }
            }

            &:not(:last-child):not(.active):hover {
              td {
                background: #3154FF;
                border-bottom-color: #3154FF;
                color: #FFFFFF;

                &.td-detail {
                  button {
                    color: #222222;
                  }
                }
              }
            }

            &.active {
              td {
                background: #FFFFFF;
                border-bottom-color: #FFFFFF;
                color: #222222;

                .ui-data {
                  &.type-static {
                    background: rgba(0, 0, 0, 0.2);
                    color: #333333;
                  }

                  .tip {
                    background: rgba(0, 0, 0, 0.1);
                    color: rgba(0, 0, 0, 0.3);
                  }
                }

                &.td-detail {
                  button {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .search-container {
      flex-basis: 196px;
      flex-grow: 0;
      padding: 12px;
      transition: margin 0.5s ease,
                  padding 0.5s ease,
                  flex-basis 0.5s ease;
      overflow: hidden;

      @media (max-width: 800px) {
        transition: none;
        margin-bottom: 24px;
      }

      &.hidden {
        flex-basis: 0;
        margin: 12px 0;
        padding: 12px 0;

        @media (max-width: 800px) {
          height: 0;
          margin: 0 0 -12px 0;
          padding: 0;
          min-height: 0;
        }
      }

      .search-options {
        height: calc(100% - 24px);
        overflow: auto;
      }
    }

    .detail-container {
      position: relative;
      flex-basis: 374px;
      flex-grow: 0;
      overflow: auto;
      padding: 6px 0 12px;
      border: 1px solid #3154FF;
      transition: margin 0.5s ease,
                  flex-basis 0.5s ease,
                  border-width 0.5s ease;

      @media (max-width: 800px) {
        transition: none;
      }

      &.hidden {
        flex-basis: 0;
        margin: 12px 0;
        padding: 12px 0;

        @media (max-width: 800px) {
          height: 0;
          margin: 0 0 -12px 0 !important;
          padding: 0 !important;
          min-height: 0;
        }
      }

      .detail-close {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        border: none;
        color: #999999;
        font-size: 16px;
        padding: 7px;
        cursor: pointer;
        transition: color 0.15s ease;

        &:hover {
          color: #FFFFFF !important;
        }
      }

      .detail-items {
        height: 100%;
        overflow: auto;

        &.edit-mode {
          height: calc(100% - 24px);
        }

        .detail-item {
          width: 374px;
          transition: background 0.15s ease;

          @media (max-width: 800px) {
            width: auto;
          }

          .detail-label {
            border-top: 1px solid rgba(255, 255, 255, 0.05);
            height: 16px;
            font-size: 11px;
            line-height: 12px;
            padding: 12px 0 2px 12px;
            color: #AAAAAA;
            margin-top: -1px;
          }

          .detail-data {
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            padding: 6px 12px 12px 12px;
          }

          .detail-edit {
            padding: 12px 10px 0 10px;
            border-bottom: 1px solid #777777;
          }

          &:first-child {
            .detail-label {
              border-top: none;
            }

            .detail-edit {
              padding-top: 12px;
            }
          }

          &:last-child {
            .detail-data {
              border-bottom-color: rgba(255, 255, 255, 0.1);
            }

            .detail-edit {
              border-bottom: none;
            }
          }

          &:hover {
            background: #333333;
          }
        }
      }

      .edit-button {
        padding: 0 12px;
      }

      &.hidden {
        flex-basis: 0;
        margin: 12px 0;
        border-width: 0;
      }
    }
  }

  .list-navigation {
    .navigation-container {
      flex-grow: 0;
    }

    .button-container {
      background: transparent;
      text-align: right;
      white-space: nowrap;

      .button-detail {
        border-radius: 12px;
        vertical-align: middle;
        display: inline-block;
        width: 0;
        overflow: hidden;
        transition: width 0.5s ease;

        &.button-1 {
          width: 83px;
        }

        &.button-2 {
          width: 166px;
        }

        &.hidden {
          width: 0;
        }

        .ui-button {
          margin-left: 12px;
        }
      }
    }

    @media (max-width: 800px) {
      &:before {
        content: "";
        display: block;
        float: none;
        clear: both;
      }

      &:after {
        content: "";
        display: block;
        float: none;
        clear: both;
      }

      .navigation-container {
        float: left;
        margin-top: 0;
        margin-bottom: 0;
      }

      .button-container {
        margin-top: 0;
        margin-bottom: 24px;
      }
    }
  }
}