.ui-input {
  min-height: 42px;

  .ui-input-label {
    height: 16px;
    font-size: 11px;
    line-height: 12px;
    padding: 0 2px;

    .tip {
      display: inline-block;
      background: rgba(255, 255, 255, 0.15);
      color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      padding: 4px 5px;
      vertical-align: middle;
      font-size: 11px;
      margin: -6px 0 -4px -2px;
    }
  }

  .ui-input-element {
    width: calc(100% - 24px);
    padding: 0 12px;
    font-size: 13px;
    line-height: 24px;
    border-radius: 12px;

    &textarea {
      resize: vertical;
    }

    &[type=checkbox] {
      width: auto;
      margin: 5px 10px 5px 0;
    }
  }

  .ui-input-description {
    text-align: right;
    height: 18px;
    font-size: 11px;
    line-height: 16px;
    padding:0 2px;
    color: #FFFFFF;

    &.error {
      color: #FFFF00;
    }
  }

  &.ui-select {
    select {
      width: 100%;
      height: 26px;
      padding: 0 9px;
      font-size: 13px;
      line-height: 26px;
      border-radius: 12px;
      border: none;
      margin-bottom: 18px;
    }
  }

  &.ui-time-range {

    .ui-time-item {
      text-align: right;
      padding-bottom: 5px;

      &::after {
        content: "";
        display: block;
        float: none;
        clear: both;
      }

      .item-values {
        float: left;
        margin-right: -20px;

        .ui-input {
          display: inline-block;
          width: 118px;
          margin-right: 3px;
          min-height: 24px;

          &:first-child {
            width: 50px;
          }

          .ui-input-description {
            display: none;
          }

          select {
            margin-bottom: 0;
          }
        }
      }
    }

    .ui-button .ui-input-element {
      width: 24px;
      height: 24px;
      padding: 0;
      margin-top: 1px;
      line-height: 26px;
    }
  }
}