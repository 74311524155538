@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');
@import 'reset.scss';

body {
  background-color: #ffffff;
  font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans TC', sans-serif;
  word-break:keep-all;
  -webkit-text-size-adjust:none;
}

.a11y-invisible {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
