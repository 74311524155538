.default-layout {
  background-color: #222222;
  color: #FFFFFF;
  font-size: 14px;

  .layout-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    height: 100vh;
    background: #3154FF;
    transition: transform 0.5s ease;
    overflow: hidden;
    z-index: 999999;

    .sidebar-logo {
      position: absolute;
      top: 0;
      left: 0;
      padding: 24px;
      width: 32px;
      height: 49px;
      font-size: 11px;
      transition: transform 0.5s ease;

      i {
        margin-bottom: 6px;
      }
    }

    .sidebar-nav {
      margin-top: 97px;
      padding: 24px 0;
      height: calc(100vh - 261px);
      overflow: auto;

      li {
        position: relative;
        overflow: hidden;
        padding: 8px 0;
        margin: -16px 0;
      }

      a {
        display: block;
        color: #FFFFFF;
        text-decoration: none;
        font-size: 16px;
        line-height: 16px;
        padding: 12px 24px;

        svg {
          position: absolute;
          top: -5px;
          right: 0;
          transform: scaleX(0);
          transform-origin: right center;
          transition: transform 0.3s ease;
        }

        &::after {
          content: "";
          position: absolute;
          top: 23px;
          right: -12px;
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 12px;
          background-color: #222222;
          transition: right 0.5s ease;
        }

        &:hover {
          svg {
            transform: scaleX(1);
          }
        }

        &.active {
          svg {
            transform: scaleX(1);
          }

          &::after {
            right: 202px;
          }
        }
      }

      .nav-children {
        margin: 8px 0 8px 24px;
        border-left: 1px solid rgba(255, 255, 255, 0.3);

        a {
          font-size: 14px;

          &.active {

            &::after {
              right: 178px;
            }
          }
        }
      }
    }

    .sidebar-info {
      padding: 24px;
      font-size: 10px;
      height: 68px;

      em {
        display: block;
        font-size: 14px;
        padding: 8px 0 12px;
      }

      .ui-button {
        margin: 0 -2px;
      }

      .toggle {
        margin-right: -32px;
        background: none;
        float: right;
        border: none;
        font-size: 24px;
        height: 24px;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }

  .layout-content {
    padding: 24px 0 12px 224px;
    transition: padding-left 0.5s ease;

    .content-title {
      margin: 0 24px 12px 24px;
      font-size: 24px;
    }

    .container-wrap {
      display: flex;
      margin: 0 12px;
    }

    .container {
      min-height: 24px;
      margin: 12px;
      border-radius: 12px;
      background: #444444;
      flex: 1 1 0;
    }
  }

  .layout-loader {
    position: relative;
    z-index: 100000;

    .dim {
      position: fixed;
      z-index: 100000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: wait;

      .icon--loader {
        position: fixed;
        top: 35px;
        right: 2px;
        transform: translate(-50%, -50%);
        cursor: wait;
      }
    }
  }

  &.close {
    .layout-sidebar {
      transform: translateX(-170px);

      @media (max-width: 600px) {
        transform: translateX(-190px);
      }

      .sidebar-logo {
        transform: translate(155px, -5px) scale(0.8);

        @media (max-width: 600px) {
          transform: translate(165px, -10px) scale(0.6);
        }
      }
    }

    .layout-content {
      padding-left: 54px;

      @media (max-width: 600px) {
        padding-left: 34px;
      }
    }
  }

  @media (min-width: 801px) {
    .only-mobile {
      display: none !important;
    }
  }

  @media (max-width: 800px) {
    .only-pc {
      display: none !important;
    }

    &:not(.open):not(.close) {
      .layout-sidebar {
        transform: translateX(-170px);

        .sidebar-logo {
          transform: translate(155px, -5px) scale(0.8);
        }
      }
    }

    .layout-content {
      padding-left: 54px !important;

      @media (max-width: 600px) {
        padding-left: 34px !important;
      }

      .container-wrap {
        display: initial;
      }
    }
  }
}