.ui-map {
  min-height: 42px;

  .ui-input-label {
    height: 16px;
    font-size: 11px;
    line-height: 12px;
    padding: 0 2px;

    .tip {
      display: inline-block;
      background: rgba(255, 255, 255, 0.15);
      color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      padding: 4px 5px;
      vertical-align: middle;
      font-size: 11px;
      margin: -6px 0 -4px -2px;
    }
  }

  .ui-map-element {
    position: relative;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;

    .pin {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
      border-radius: 100px;
      width: 16px;
      height: 16px;
      border: 13px solid #3154FF;
      transform: translate(-50%, calc(-50% - 20px));

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(1) translate(-50%, calc(-50% + 8px));
      }
    }
  }

  .ui-input-description {
    text-align: right;
    height: 18px;
    font-size: 11px;
    line-height: 16px;
    padding:0 2px;
    color: #FFFFFF;

    &.error {
      color: #FFFF00;
    }
  }
}