.sign-in {
  .sign-in-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 196px;
    height: 360px;
    background-color: #3154FF;
    border-radius: 12px;
    padding: 16px;
    box-sizing: border-box;

    .graphic--hole {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: 52px;
      height: 10px;
      background-color: #222222;
      border-radius: 12px;
    }

    .graphic--strap {
      position: absolute;
      top: calc(-50vh + 21px);
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
      height: 50vh;
      border-radius: 4px;
      background-color: #FFFFFF;
    }

    .sign-in-logo {
      text-align: center;
      padding: 45px 0 100px 0;
    }

    .sign-in-buttons {
      margin-top: 12px;
    }
  }
}