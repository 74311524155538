.ui-data {
  line-height: 1.4;

  .data-item {
    position: relative;
    display: block;
    padding-left: 67px;
    line-height: 1.7;

    &::after {
      content: " "
    }

    &.no-title {
      padding-left: 0;
    }

    .data-title {
      position: absolute;
      left: 0;
      width: 52px;
      text-align: center;
      line-height: 1.1;
      background: rgba(255, 255, 255, 0.2);
      color: #222222;
      padding: 3px 4px;
      margin: 0 6px 0 -1px;
      border-radius: 4px;
      font-family: monospace;
      white-space: nowrap;
    }

    .ui-data {
      margin-left: -68px;
      margin-bottom: 5px;
    }

    &.type-image {
      img {
        max-width: 100%;
        max-height: 400px;
      }
    }
  }

  .tip {
    display: inline-block;
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    padding: 4px 5px;
    vertical-align: middle;
    font-size: 11px;
    margin: -6px 0 -4px 5px;
    line-height: 1.1;
    transition: background 0.15s ease,
                color 0.15s ease;
  }

  &.type-static {
    background: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
    padding: 3px 4px;
    margin: 0 -1px;
    border-radius: 4px;
    font-family: monospace;
    transition: background 0.15s ease,
                color 0.15s ease;
  }

  &.type-password {
    background: rgba(255, 255, 255, 0.2);
    color: #222222;
    padding: 3px 4px;
    margin: 0 -1px;
    border-radius: 4px;
  }

  &.type-image {
    img {
      max-width: 100%;
      max-height: 400px;
    }
  }

  &.type-file {
    img {
      max-width: 100%;
      max-height: 400px;
    }

    a {
      color: inherit;
    }
  }
}

.ui-data-lang {
  background: rgba(255, 255, 255, 0.2);
  color: #222222;
  padding: 3px 4px;
  margin: 0 6px 0 -1px;
  border-radius: 4px;
  font-family: monospace;
  white-space: nowrap;
}

.ui-data-item {
  margin-bottom: 5px;
  min-height: 23.8px;

  &.item-lang {
    position: relative;
    padding-left: 67px;
    line-height: 1.7;

    .ui-data-lang {
      position: absolute;
      left: 0;
      width: 52px;
      text-align: center;
      line-height: 1.1;
    }
  }
}