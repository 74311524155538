.ui-button {
  display: inline-block;

  &.full {
    display: block;
  }

  .ui-input-element {
    padding: 0 16px;
    font-size: 13px;
    line-height: 24px;
    border-radius: 12px;
    text-align: center;
    background-color: #222222;
    color: #3154FF;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.15s ease,
                opacity 0.15s ease;

    &.full {
      width: 100%;
    }

    &:hover {
      color: #FFFFFF;
    }

    &:disabled {
      opacity: 0.3;
    }

    &.color-blue {
      background-color: #3154FF;
      color: #222222;

      &:hover {
        color: #FFFFFF;
      }
    }

    &.color-white {
      background-color: #FFFFFF;
      color: #3154FF;

      &:hover {
        color: #222222;
      }
    }
  }
}