.ui-navigation {
  .page-buttons {
    display: inline-block;
    white-space: nowrap;

    button {
      background: transparent;
      cursor: pointer;
      border: none;
      color: #ffffff;
      transition: opacity 0.15s ease,
                  background 0.15s ease;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 2px;
      border-radius: 12px;

      &:not(:disabled):not(.active):hover {
        background: #222222;
      }

      &.active {
        background: #3154FF;
        cursor: default;
      }

      &.arrow {
        width: 20px;

        i {
          margin: 0 -2px;
        }
      }

      &:disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
  }
}